<template>
  <div class="content" style="height: calc(100vh -  1.2432rem); margin-top: 1.2432rem">
    <!-- 顶部导航 -->
    <van-nav-bar
      title="订单详情"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="content-center">
      <!-- 交易完成 -->
      <div class="oderFinished">
        <img
          style="width: 1.7568rem; height: 1.7568rem"
          src='src="../../assets/img/takeOutFood/share.png"'
        />
        <span>交易完成</span>
      </div>
      <div class="shop">
        <div>
          <img src='src="../../assets/img/takeOutFood/share.png"' />
        </div>
        <span style="margin: 0 0.1892rem">曼斯菲尔旗舰店</span>
        <van-icon name="arrow" />
      </div>
      <div class="shop-item">
        <div class="shop-info">
          <img
            style="width: 2.1622rem; height: 2.1622rem"
            src='src="../../assets/img/takeOutFood/share.png"'
          />
          <div class="info">
            <div class="desc-price">
              <span class="desc"
                >吃的搜索得寸进尺吃的搜索得寸进尺吃的搜索得</span
              >
              <span>122.00</span>
            </div>
            <div class="spec">
              <span>规格： 白色</span>
              <span>*1</span>
            </div>
          </div>
        </div>
        <div style="float: right">
          <van-button round size="small">申请售后</van-button>
        </div>
      </div>
      <div class="all-price">
        <div class="total-price">
          <span>商品总价</span>
          <span>￥223.00</span>
        </div>
        <div class="road-price">
          <span>运费</span>
          <span>￥0.00</span>
        </div>
        <div class="coupon">
          <span>优惠劵</span>
          <span style="color: red">￥0.00</span>
        </div>
        <div class="buy-price">
          <span>实付</span>
          <span style="color: red">￥0.00</span>
        </div>
      </div>
      <!-- 物流状态 -->
      <div class="logistics">
        <div class="log-content">
          <van-steps direction="vertical" :active="2">
            <van-step>
              <h3>【城市】物流状态1</h3>
              <p>2016-07-12 12:40</p>
            </van-step>
            <van-step>
              <h3>【城市】物流状态2</h3>
              <p>2016-07-11 10:00</p>
            </van-step>
            <van-step>
              <h3>快件已发货</h3>
              <p>2016-07-10 09:30</p>
            </van-step>
          </van-steps>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  // stepInfo() {
  //   console.log(1111);
  // },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);
  },
  methods: {
    // rem
    setRem() {
      let screenWidth = document.documentElement.getBoundingClientRect().width;
      document.documentElement.style.fontSize = screenWidth / 10 + "px";
    },
    onClickLeft() {
      Toast("返回");
    },
    onClickRight() {
      Toast("按钮");
    },
  },
};
</script>

<style lang="less" scoped>
.content-center {
  padding: 0 0.4054rem;
  .oderFinished {
    margin-top: 0.4054rem;
    display: flex;
    span {
      margin: -3px 0 0 0.2703rem;
    }
  }
  .shop {
    display: flex;
    height: 0.8108rem;
    align-items: center;
    margin-top: 0.2703rem;
    div {
      width: 0.5405rem;
      height: 0.5405rem;
      box-sizing: border-box;
      border-radius: 3px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .shop-item {
    margin-top: 0.2703rem;
    .shop-info {
      display: flex;
      .info {
        margin-left: 0.2703rem;
        flex: 1;
        width: 1.3514rem;
        height: 1.3514rem;
        // background-color: pink;
        .desc-price {
          display: flex;
          justify-content: space-between;
          .desc {
            display: inline-block;
            width: 65%;
          }
        }
        .spec {
          color: #999999;
          margin-top: 0.2162rem;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .all-price {
    margin-top: 1.0811rem;
    .total-price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2703rem;
    }
    .road-price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2703rem;
    }
    .coupon {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2703rem;
    }
    .buy-price {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2703rem;
    }
  }
  .logistics {
    // height: 200px;
    width: 100%;
    // background-color: pink;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    .log-content {
      margin: 0.6757rem 0;
      // background-color: pink;
      // height: 100px;
      width: 100%;
    }
  }
}
/deep/ .van-button__text {
  margin: 0 0.4054rem;
}
/deep/ .van-nav-bar {
  position: fixed;
  width: 100%;
  top: 0;
}
</style>